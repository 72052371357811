<template>
  <div class="productDetails">
    <Breadcrumb title="My Cart"/>

    <div class="product-background">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
          </div>
        </div>
      </div>
  
      <div class="container">
        <div class="mt-5">
          <div class="row">
            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                <h5>Order details</h5>
                <hr style="margin: 10px 0">
                <!-- <h5>Order ID: <span class="or-h">{{ order.id }}</span></h5> -->
                <h6>Date: <span class="or-h">{{ order.order_date }}</span></h6>
                <h6 v-if="config.VAT_RATE > 0">Total Price (with {{ config.VAT_PERCENTAGE }}% vat and Delivery Charge): <span class="or-h">{{ parseFloat(order.grand_total).toFixed(2) }}</span></h6>
                <h6 v-else>Total Price (with Delivery Charge): <span class="or-h">{{ parseFloat(order.grand_total).toFixed(2) }}</span></h6>
                <h6>Status: <span class="or-h">{{ order.status }}</span></h6>
              <div class="cart-table table-responsive">
                <table class="table table-striped table-bordered" width="100%" cellspacing="0">
                  <thead>
                    <tr>
                      <th style="text-align: center" class="product-remove">S/N</th>
                      <th class="product-subtotal">Image</th>
                      <th class="product-subtotal">Name</th>
                      <th class="product-subtotal">Quantity</th>
                      <th class="product-subtotal">Total Price (Tk)</th>
                    </tr>
                  </thead>
                  <tr v-for="(item, index)  in order.products" :key="index">
                    <td style="text-align: center">
                       {{ index+1 }}
                    </td>

                    <td style="text-align: left;">
                      <img style="display:block; margin:auto" width="100" :src="item.images[0]" alt="">
                    </td>

                    <td>
                        <router-link class="action" 
                            :to="{name:'newestore.newproduct.show', params:{id: item.product_id}, query:{name:replaceSpace(item.name)}}">
                            {{ item.name }}   
                        </router-link>
                    </td>

                    <td>
                      {{ item.quantity }}
                    </td>
                    <td>
                      {{ parseFloat(item.total_price - item.product_discount).toFixed(2) }}
                    </td>
                    
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="chat">
        <div class="chat-box">
          <div class="row">
            <div class="col-md-6">
              <ChatBox 
                v-if="chatBox"
                :item="chatItem" 
                @onClickSend="sendMessage" 
                @closeChatBox="toggleChatBox()"
            />
            </div>
            <div style="padding: 25px" class="col-md-6">
              <SupportTicket 
               v-if="ticket"
               :tickets="tickets"
               @onClickTicket="openChatBox"
               @closeTicketBox="closeTicketBox"
               @onClickAddNewTicket="openAddNewTicketModal"
              />
            </div>
          </div>
            
            
        </div>
        <div class="icon">
            <i @click="toggleTicketBox()" class="fa fa-wechat chat-btn-style"></i>
        </div>
    </div>
    <notifications group="cart" position="top left" />
    <!-- add new ticket modal -->
    <v-app>
      <template>
        <div class="text-center">
          <v-dialog
            v-model="dialog"
            width="500"
          >
             <v-card>
              <!-- <v-card-title
                class="headline grey lighten-2"
                primary-title
              >
                Privacy Policy
              </v-card-title> -->

              <v-form
                ref="form"
               
                v-model="valid"
                :lazy-validation="lazy"
                class="pa-3 mt-65"
            >

            <v-text-field
                v-model="newTicketDAta.title"
                :rules="[
                v => !!v || 'Title is required']"
                label="Title"
                outlined
                dense
                required
            ></v-text-field>
            <v-textarea
              dense
              outlined
              v-model="newTicketDAta.details"
              name="input-7-4"
              label="Details"
              :rules="[
                v => !!v || 'Details is required']"
            ></v-textarea>

            <v-btn
              small
              
              :disabled="!valid"
              style="color:#fff"
              color="deep-orange darken-2"
              @click="addNewTicket()"
              :loading="loading"
            >
                Add
            </v-btn>
            <v-btn
              small
              style="color:#fff; margin-left:10px"
              color="primary"
              @click="dialog = false"
              
            >
                Close
            </v-btn>
        </v-form>
            </v-card>
          </v-dialog>
        </div>
      </template>
      </v-app>
    <!-- add new ticket modal -->
    <!-- snackbar -->
      <v-snackbar v-model="snackbar">
          {{ text }}
          <v-btn color="pink" text  @click="snackbar = false">
              Close
          </v-btn>
      </v-snackbar>
  <!-- snackbar -->
        <notifications group="update" position="top middle" />
  </div>
</template>

<script>
import moment from 'moment'
import {mapGetters} from 'vuex'
import Breadcrumb from "@/components/common/BreadcrumbComponent";
import ChatBox from "@/components/product/ChatBox";
import SupportTicket from "@/components/product/SupportTicket";
import config from '@/config.js';
export default {
    name: 'order-detail',
    components: { Breadcrumb, ChatBox, SupportTicket},
    data(){
        return{
            lazy: false,
            valid: false,
            dialog: false,
            loading: false,
            text: '',
            snackbar: false,
            newTicketDAta: {},
            chatItem: {},
            chatBox: false,
            message: {},
            ticket: false,
            id: this.$route.params.id,
        }
    },
    methods: {
      async fetchOrderData(id) {
      this.$store.dispatch('order/ACT_ORDER', id);
      this.$store.dispatch('chat/ACT_TICKET_LIST', id);
      },
      replaceSpace(name){
            return name.replace(/[\W_]/g, "-")
        },
        toggleChatBox(){
            this.chatBox = !this.chatBox
        },
        toggleTicketBox(){
          this.ticket = !this.ticket
        },
        closeTicketBox(){
          this.ticket = false
        },
        sendMessage(message){
            this.$store.dispatch('chat/ACT_STORE_MESSAGE', message)
        },
        scrollToBottom(){
            document.getElementById('messages').scrollTop = document.getElementById('messages').scrollHeight;
        },
        openChatBox(item){
          this.chatBox = true
          //this.chatItem = Object.assign({}, item);
          this.chatItem = item;
        },
        openAddNewTicketModal(){
          this.dialog=true
        },
        async addNewTicket(){
          this.loading = true
          this.newTicketDAta.order_id = this.$route.params.id
            let message = await this.$store.dispatch("chat/ACT_STORE_TICKET", this.newTicketDAta)
            if(message.success == true){
                this.loading = false
                this.newTicketDAta = {}
                this.dialog = false
            this.$notify({
                group: 'update',
                type: 'success',
                title: 'Success',
                text: 'Ticket added successfully!!',
            });
            }else{
                this.loading = false
                this.snackbar = true
                this.text = message.message
                
            }
        }
    },//end of methods
    computed: {
      config() {
        return config
      },
      ...mapGetters({
          order: 'order/GET_ORDER',
          tickets: 'chat/GET_TICKET'
      })
    },
    filters: {
      formatDate(date){
        return moment(date).format('DD-MMM-YYYY');
      }
    },
    watch: {
    $route(to) {
      // Watch for route changes
      this.id = to.params.id;
      this.fetchOrderData(this.id);
    }
  },
  mounted() {
    this.fetchOrderData(this.id);
  }
    
}
</script>

<style scoped>
    /*@font-face {
      font-family: myThirdFont;
      src: url('/fonts/Ubuntu-M.ttf');
    }*/
  .breadcrumb-area:before {
    background-color: white !important;
    width: 0%;
    height: 0%;
}
  p, a, h1, h2, h3, h4, h5, h6, span{
      font-family: 'Ubuntu', sans-serif;
    }
    .btn {
      border-radius: 16px;
      font-size: 14px;
      padding: 0px 15px;
      height: 30px;
      line-height: 30px;
    }

    .fa-plus {
      transform: rotate(45deg);
    }
    .action {
      cursor: pointer;
    }
    thead {
      display: table-header-group;
      vertical-align: middle;
      border-color: inherit;
      background-color: black;
      color: white;
    }
    .cart-product {
      margin-bottom: 122px;
    }
    .table td,
    .table th {
      padding: 0.75rem;
      vertical-align: middle;
      border-top: 1px solid #e9ecef;
      text-align: center;
    }

    .product-heading {
      text-align: center;
      color: white;
      margin-top: 45px;
    }
    .product-heading span {
      padding: 3px;
      text-align: center;
      font-weight: bold;
      color: #fff;
    }
    .product-info {
      background-color: white;
      margin-top: 45px;
      padding: 50px;
    }
    
    .action{
      font-weight: bold;
    }
    .action:hover{
      color: firebrick;
    }
    .or-h{
        color: firebrick;
    }
    .position{
      margin-top: -15px;
      margin-left: 18px;
      font-size: 14px;
    }
    .color{
      color: firebrick;
    }
    .chat-btn-style{
        cursor: pointer;
        opacity: 0.5;
        color: #e54c2a;
        font-size: 50px;
        position: fixed;
        bottom: 15px;
        right:13px;
        transition: .5s;
        z-index: 999;
    }
    .chat-btn-style:hover{
        opacity: 1;
    }
    .chat-box{
        z-index: 99;
        position: fixed;
        bottom: 35px;
        right:200px;
    }
    .theme--light.v-application {
    background: transparent;
        color: rgba(0, 0, 0, 0.87);
    }
    textarea:focus {
    display: none;
}
</style>